<template>
  <v-card>
    <v-card-title>
      <span class="me-3">Texte d'introduction du site web</span>
      <v-spacer></v-spacer>
      <div class="d-flex align-center">
        <v-avatar
          v-for="lang in langs"
          :key="lang"
          class="v-avatar-light-bg ml-2"
          size="32"
          @click="getPage(lang)"
        >
          <v-btn
            icon
            :color="currentLang === lang ? 'primary' : 'secondary'"
          >
            {{ lang }}
          </v-btn>
        </v-avatar>
      </div>
    </v-card-title>
    <v-card-text>
      <v-form>
        <v-text-field
          v-model="title"
          outlined
          label="Titre"
          placeholder="titre"
        ></v-text-field>
        <tiptap-vuetify
          v-model="content"
          :extensions="extensions"
        ></tiptap-vuetify>
        <div
          class="text-center"
        >
          <v-btn
            color="primary"
            class="mt-6 mb-4 mr-6"
            :loading="loading"
            @click="updatePage"
          >
            Enregistrer
          </v-btn>
        </div>
      </v-form>
    </v-card-text>
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      :timeout="2000"
      outlined
      centered
      text
    >
      <div class="text-center">
        {{ snackbarMsg }}
      </div>
    </v-snackbar>
  </v-card>
</template>

<script>
import axios from '@axios'
import themeConfig from '@themeConfig'
import { onMounted, ref } from '@vue/composition-api'
import {
  Bold, BulletList, HardBreak, Heading, HorizontalRule, Italic, Link, ListItem, OrderedList, Paragraph, TiptapVuetify, Underline,
} from 'tiptap-vuetify'

// import CustomLink from '@/@core/layouts/components/tiptap/extensions/CustomLink'

export default {
  components: {
    TiptapVuetify,
  },
  setup() {
    const extensions = ref([
      Bold,
      Underline,
      Italic,
      ListItem,
      Link,
      BulletList,
      OrderedList,
      [Heading, {
        options: {
          levels: [2, 3, 4],
        },
      }],
      Paragraph,
      HorizontalRule,
      HardBreak,
    ])
    const loading = ref(false)
    const content = ref(null)
    const title = ref(null)
    const snackbar = ref(false)
    const snackbarColor = ref('success')
    const snackbarMsg = ref('')
    const currentLang = ref('fr')
    const id = 1

    const { langs } = themeConfig.app

    const getPage = async lang => {
      loading.value = true
      await axios.get(`page/get/${lang}/${id}`).then(response => {
        content.value = response.data.full_text
        title.value = response.data.title
        currentLang.value = lang
        loading.value = false
      }).catch(e => {
        console.error(e)
        snackbarMsg.value = `Une erreur serveur est survenue : ${e}`
        snackbarColor.value = 'error'
        snackbar.value = true
        loading.value = false
      })
    }

    const updatePage = async () => {
      snackbar.value = true
      loading.value = true
      await axios.post('page/update', {
        lang: currentLang.value,
        id,
        title: title.value,
        full_text: content.value,
      }).then(() => {
        snackbarMsg.value = 'Enregistrement effectué avec succès.'
        snackbarColor.value = 'success'
        snackbar.value = true
        loading.value = false
      }).catch(e => {
        snackbarMsg.value = `Une erreur serveur est survenue : ${e}`
        snackbarColor.value = 'error'
        snackbar.value = true
        loading.value = false
        console.error(e)
      })
    }

    onMounted(() => {
      getPage(langs[0], 1)
    })

    return {
      extensions,
      langs,
      currentLang,
      title,
      content,
      loading,
      snackbar,
      snackbarColor,
      snackbarMsg,
      getPage,
      updatePage,
    }
  },
}
</script>

<style scoped>
</style>
